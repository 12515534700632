import React from "react";
import styled from "styled-components";

import { PAGE_SIDE_PADDING, ScreenSize } from "../../styling/constants";
import { ColorTheme } from "../../styling/theme";
import { getFormattedPrice } from "../../util/currencyUtil";

const Container = styled.div`
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px ${PAGE_SIDE_PADDING}px;
  border-bottom: 1px solid ${ColorTheme.sectionDividerColor};
`;

const LogoContainer = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 20px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const AmountContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h6 {
    font-size: 1.4rem;
    font-weight: 100;
    margin-bottom: 6px;
  }

  p {
    font-size: 1.6rem;
    font-weight: bold;
  }
  p.customer-passed-fee {
    font-size: 1rem;
    font-weight: 300;
  }
`;

export const CancelTransactionButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  background-color: #f1f1f1;

  svg {
    height: 0.8rem;
    width: auto;
    fill: #404040;
    margin-right: 5px;
  }

  p {
    font-size: 1.2rem;
    font-weight: bold;
    color: #404040;
  }

  @media (min-width: ${ScreenSize.XS}px) {
    display: none;
  }
`;

interface Props {
  transactionCompleted: boolean;
  logoUrl: string;
  currencyCode: string;
  amount: number;
  customerEmail?: string;
  cancelTransactionHandler: () => void;
  surcharge: number;
  passFeeToCustomer: boolean;
}

export default function PaymentHeader(props: Props) {
  const {
    transactionCompleted,
    logoUrl,
    currencyCode,
    amount,
    customerEmail,
    cancelTransactionHandler,
    surcharge,
    passFeeToCustomer,
  } = props;

  return (
    <Container>
      <LogoContainer>
        <img src={logoUrl} alt="logo" />
      </LogoContainer>

      <AmountContainer>
        <h6>{customerEmail || "You're paying"}</h6>
        <p>{getFormattedPrice(amount, currencyCode)}</p>
        {surcharge > 0 && passFeeToCustomer && (
          <p className="customer-passed-fee">FEE: {getFormattedPrice(surcharge, currencyCode)}</p>
        )}
      </AmountContainer>

      {!transactionCompleted && (
        <CancelTransactionButton onClick={cancelTransactionHandler}>
          <p>Cancel</p>
        </CancelTransactionButton>
      )}
    </Container>
  );
}
