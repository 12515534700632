import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../store/RootReducer";
import {
  NonCardOption,
  PaymentChannelType,
  PaymentParams,
} from "../features/payment/presentation/store/paymentProperties/types";
import { defaultEnabledChannelsMap } from "../util/constants";
import { PaymentChannelProps } from "../features/payment/util/types";
import { paymentChannelsProps } from "../features/components/Constants";

type EnabledChannelsMap = {
  [key in PaymentChannelType]: boolean;
};


/**
 * Helper hook to simplify boilerplates around payment methods
 *  @param isChannelEnabled(methodType) returns true if channel enabled to be used
 */
const usePaymentChannelHelper = () => {
  const { paymentParams, paymentChannels } = useSelector((state: AppState) => ({
    paymentParams: state.payment.paymentProperties
      .paymentParams as PaymentParams,
    paymentChannels: state.payment.paymentProperties
      .paymentChannels as NonCardOption[],
  }));

  const [enabledChannelsMap, setEnabledChannelsMap] =
    useState<EnabledChannelsMap>(defaultEnabledChannelsMap);

  const isUgandan = paymentParams.currencyCode === "800";

  const getDrcMobileMoney = paymentParams?.nonCardOptions?.find(option => option.providerCode === 'DRC_MOBILE_MONEY');
  
  const isDRC = getDrcMobileMoney?.providerCode === 'DRC_MOBILE_MONEY';
  
  
  let paymentProps: PaymentParams = window.iswPaymentProps;
  const { acquiredBy } = paymentProps;

  const isChannelEnabled = (methodType: PaymentChannelType) => {
    return !!enabledChannelsMap[methodType];
  };

  const enabledMethodsData: PaymentChannelProps[] = useMemo(() => {
    const enabledChannels = paymentChannelsProps.filter((method) =>
      isChannelEnabled(method.type)
    );

    return enabledChannels;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledChannelsMap]); 

  const { currentPaymentChannel } = useSelector((state: AppState) => ({
    currentPaymentChannel: state.payment.currentPaymentChannel,
  }));

  const enabledPaymentMinusCurrentData = enabledMethodsData.filter(
    (method) => method.type !== currentPaymentChannel
  );

  useEffect(() => {
    setEnabledChannelsMap(() => {
      const newEnabledChannelsMap = {
        ...defaultEnabledChannelsMap,
      };

      paymentChannels.forEach((channel) => {
        const { providerCode, enabled } = channel;
        newEnabledChannelsMap[providerCode] = enabled;

        if (!isUgandan && providerCode === "UGANDA_MOBILE_MONEY") {
          newEnabledChannelsMap["UGANDA_MOBILE_MONEY"] = false;
        }

        if (isUgandan) {
          if (
            providerCode !== "CARD" &&
            providerCode !== "UGANDA_MOBILE_MONEY"
          ) {
            newEnabledChannelsMap[providerCode] = false;
          }

          return newEnabledChannelsMap;
        }

        if (!isDRC && providerCode === "DRC_MOBILE_MONEY") {
          newEnabledChannelsMap["DRC_MOBILE_MONEY"] = false;
        }

        if (isDRC) {
          if (
            providerCode !== "CARD" &&
            providerCode !== "DRC_MOBILE_MONEY"
          ) {
            newEnabledChannelsMap[providerCode] = false;
          }

          return newEnabledChannelsMap;
        }

        if (providerCode === "CARD") {
          newEnabledChannelsMap["CARD"] = enabled;
          newEnabledChannelsMap["WALLET"] = enabled;
        }

        if (providerCode === "CUSTOMER_WALLET") {
          newEnabledChannelsMap["OPAY"] = enabled;
        }
      });

      if (acquiredBy === "ZIB") {
        newEnabledChannelsMap["CARD"] = true;
        newEnabledChannelsMap["TRANSFER"] = true;
        newEnabledChannelsMap["WALLET"] = false;
        newEnabledChannelsMap["CUSTOMER_WALLET"] = false;
        newEnabledChannelsMap["QR"] = false;
        newEnabledChannelsMap["USSD"] = false;
        newEnabledChannelsMap["CRD"] = false;
        newEnabledChannelsMap["GOOGLE_PAY"] = false;
        newEnabledChannelsMap["UGANDA_MOBILE_MONEY"] = false;
        newEnabledChannelsMap["OPAY"] = false;
      } else if (acquiredBy === "HALO") {
        newEnabledChannelsMap["CARD"] = true;
        newEnabledChannelsMap["TRANSFER"] = true;
        newEnabledChannelsMap["WALLET"] = true;
        newEnabledChannelsMap["QR"] = true;
        newEnabledChannelsMap["USSD"] = true;
        newEnabledChannelsMap["CRD"] = true;
        newEnabledChannelsMap["GOOGLE_PAY"] = true;
      }

      return newEnabledChannelsMap;
    });

  }, [acquiredBy, isUgandan, paymentChannels, isDRC]);

  return {
    isChannelEnabled,
    merchantPaymentProps: paymentProps,
    enabledMethodsData,
    allMethodsData: paymentChannelsProps,
    enabledPaymentMinusCurrentData,
    currentPaymentChannel,
  };
};

export default usePaymentChannelHelper;
